import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { DateTime } from 'luxon';

import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { LoadingButton } from '@mui/lab';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';

import { useCheckExists } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormAutocompleteInput from './common/FormAutocompleteInput.jsx';
import FormDatePicker from './common/FormDatePicker.jsx';
import FormStringInput from './common/FormStringInput.jsx';

const statusOptions = [
  { id: 'To Do', name: 'To Do' },
  { id: 'In Progress', name: 'In Progress' },
  { id: 'Under Review', name: 'Under Review' },
  { id: 'Not Applicable', name: 'Not Applicable' },
  { id: 'Completed', name: 'Completed' },
];

export const documentIsApproved = (document, task) => {
  if (!document) {
    return false;
  }
  if (document.workflow_state.includes('Approved')) {
    return true;
  }
  if (document.last_approved_version) {
    const [versionMajorString, versionMinorString] = document.version.split('.');
    const versionMajor = Number(versionMajorString);
    const versionMinor = Number(versionMinorString);
    const minVersion = task.documentMinimumVersion || '1.0';
    const [targetMajorString, targetMinorString] = minVersion.split('.');
    const targetMajor = Number(targetMajorString);
    const targetMinor = Number(targetMinorString);

    if (versionMajor > targetMajor) {
      return true;
    }
    if (versionMajor < targetMajor) {
      return false;
    }
    if (versionMinor >= targetMinor) {
      return true;
    }
  }
  return false;
};

function ApprovalForm({
  // Props
  task,
  plcId,
  setTableReload,
}) {
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const { user } = useAuth0();

  const formTitle = React.useMemo(
    () => (task?.name ? 'Update PLC Task' : 'Add PLC Task'),
    [task?.name]
  );
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  //---------------------------------------------------------------------------
  // Document ID Input field
  //---------------------------------------------------------------------------
  const { documents, documentOptions } = React.useContext(DocumentContext);
  const checkDocumentExists = useCheckExists(
    documents,
    'id',
    null,
    'Please provide a valid document ID.'
  );

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const today = React.useMemo(() => DateTime.now(), []);
  const defaultValues = React.useMemo(
    () => ({
      name: task?.name || '',
      dueDate: task?.dueDate ? DateTime.fromISO(task?.dueDate) : null,
      documentNumber: documentOptions?.find((option) => option.id === task?.documentNumber) || '',
      documentMinimumVersion: task?.documentMinimumVersion || '1.0',
      status: statusOptions?.find((option) => option.id === task?.status) || '',
      statusDate: today,
    }),
    [
      documentOptions,
      task?.documentMinimumVersion,
      task?.documentNumber,
      task?.dueDate,
      task?.name,
      task?.status,
      today,
    ]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      // Clean up the form data
      /* eslint-disable no-param-reassign */
      if (data.documentNumber) {
        data.documentNumber = data.documentNumber?.id;
      } else {
        data.documentNumber = undefined;
      }
      if (!['', null, undefined].includes(data.dueDate)) {
        data.dueDate = data.dueDate.toISODate();
      } else {
        data.dueDate = undefined;
      }
      if (!['', null, undefined].includes(data.statusDate)) {
        data.statusDate = data.statusDate.toISODate();
      } else {
        data.statusDate = undefined;
      }
      if (data.status) {
        data.status = data.status?.id;
      }
      data.plcId = plcId;
      data.name = task.name;
      data.trackedDocument = task.trackedDocument;
      data.section = task.section;
      data.order = task.order;
      if (!task.trackedDocument) {
        data.userName = user.name;
        data.userPicture = user.picture;
      }
      /* eslint-enable no-param-reassign */

      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        if (task?.id) {
          const taskUrlComponent = encodeURIComponent(task.id);

          await axios({
            method: 'PATCH',
            url: `/api/plcTasks/${taskUrlComponent}`,
            data,
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/plcTasks`,
            data,
          });
        }

        // Reset and close the modal
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }

        setSuccess('Successfully saved changes!');
      } catch (err) {
        setError(err?.response?.data?.error || err?.message);
      }
      setLoading(false);
    },
    [
      plcId,
      task.name,
      task.trackedDocument,
      task.section,
      task.order,
      task.id,
      user.name,
      user.picture,
      setTableReload,
      setSuccess,
    ]
  );

  const handleOpen = React.useCallback(
    (event) => {
      event.stopPropagation();
      reset(defaultValues);
      setOpen(true);
    },
    [defaultValues, reset]
  );
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const tooltip = React.useMemo(() => {
    if (task.trackedDocument) {
      if (!task.documentNumber) {
        return 'Click to enter a document number for this tracked task.';
      }
      const document = documents?.find((doc) => doc.id === task.documentNumber);
      if (documentIsApproved(document, task)) {
        return 'This document has been approved on KT.';
      }
      return 'This document needs review and approval on KT.';
    }
    if (task.userName) {
      return `${task.userName} updated this task.`;
    }
    return 'Click to update the status of this task.';
  }, [documents, task]);

  const chipLabel = React.useMemo(() => {
    if (['To Do', 'Not Applicable'].includes(task.status)) {
      return task.status;
    }
    if (task.statusDate) {
      const date = DateTime.fromISO(task.statusDate);
      return `${task.status} ${date.toLocaleString()}`;
    }
    return task.status;
  }, [task.status, task.statusDate]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      <Tooltip arrow title={tooltip}>
        <Chip
          avatar={
            (task.userName || task.documentNumber) && (
              <Avatar alt={task.userName} src={task.userPicture} />
            )
          }
          onClick={handleOpen}
          label={chipLabel}
          color={task.statusColor || 'primary'}
          size="small"
          disabled={!documents.length}
          sx={{ my: 0.5 }}
        />
      </Tooltip>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClick={disablePropagation}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <TaskAltIcon color="secondary" sx={{ mr: 2 }} />
          {formTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid size={{ xs: 12, sm: 8 }}>
              <FormStringInput
                control={control}
                label="Task"
                name="name"
                disabled
                required
                rules={{
                  required: 'Please enter a name for the task',
                  maxLength: {
                    value: 128,
                    message: 'Task name should be less than 128 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <FormDatePicker control={control} label="Due Date" name="dueDate" />
            </Grid>
            {task.trackedDocument ? (
              <>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <FormAutocompleteInput
                    control={control}
                    label="Document Number"
                    name="documentNumber"
                    autoFocus
                    options={documentOptions}
                    rules={{
                      validate: (value) => checkDocumentExists(Number(value)),
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <FormStringInput
                    control={control}
                    label="Minimum Version"
                    name="documentMinimumVersion"
                    required
                    rules={{
                      required: 'Please enter a minimum approved version for this document',
                      maxLength: {
                        value: 32,
                        message: 'Version should be less than 32 characters',
                      },
                      validate: (value) => {
                        if (!value?.match(/^\d+\.\d+$/)) {
                          return 'Version must be in the format XX.YY';
                        }
                        return true;
                      },
                    }}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <FormAutocompleteInput
                    control={control}
                    label="Status"
                    name="status"
                    options={statusOptions}
                    autoFocus
                    required
                    rules={{
                      required: 'Please a status for this task',
                      maxLength: {
                        value: 128,
                        message: 'Status should be less than 128 characters',
                      },
                    }}
                  />
                </Grid>
                <Grid
                  size={{
                    xs: 12,
                    sm: 4,
                  }}
                >
                  <FormDatePicker control={control} label="Status Date" name="statusDate" />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2 }}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              Update
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default React.memo(ApprovalForm);

import React from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { DateTime } from 'luxon';

import Edit from '@mui/icons-material/Edit';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';

import { useCheckExists } from '@tzmedical/react-hooks';

import axios from '../axiosClient.js';
import DocumentContext from '../contexts/DocumentContext.jsx';
import useAuth0Roles from '../hooks/auth0Roles.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormAutocompleteInput from './common/FormAutocompleteInput.jsx';
import FormDatePicker from './common/FormDatePicker.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmAction from './ConfirmAction.jsx';

function PLCForm({
  // Props
  plc,
  setTableReload,
}) {
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const formTitle = React.useMemo(() => (plc?.id ? 'Update PLC' : 'Add PLC'), [plc?.id]);
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  //---------------------------------------------------------------------------
  // Document ID Input field
  //---------------------------------------------------------------------------
  const { documents, documentOptions } = React.useContext(DocumentContext);
  const checkDocumentExists = useCheckExists(
    documents,
    'id',
    null,
    'Please provide a valid document ID.'
  );

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const today = React.useMemo(() => DateTime.now(), []);
  const defaultValues = React.useMemo(
    () => ({
      name: plc?.name || '',
      icrDocumentNumber:
        documentOptions?.find((option) => option.id === plc?.icrDocumentNumber) || '',
      engineeringRelease: plc?.engineeringRelease
        ? DateTime.fromISO(plc?.engineeringRelease)
        : today,
      launchDate: plc?.launchDate ? DateTime.fromISO(plc?.launchDate) : today,
      thirdYearRevenue: plc?.thirdYearRevenue || '',
    }),
    [
      documentOptions,
      plc?.engineeringRelease,
      plc?.icrDocumentNumber,
      plc?.launchDate,
      plc?.name,
      plc?.thirdYearRevenue,
      today,
    ]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      // Clean up the form data
      /* eslint-disable no-param-reassign */
      if (!['', null, undefined].includes(data.thirdYearRevenue)) {
        data.thirdYearRevenue = Number(data.thirdYearRevenue);
      } else {
        data.thirdYearRevenue = undefined;
      }
      if (!['', null, undefined].includes(data.engineeringRelease)) {
        data.engineeringRelease = data.engineeringRelease.toISODate();
      } else {
        data.engineeringRelease = undefined;
      }
      if (data.launchDate) {
        data.launchDate = data.launchDate.toISODate();
      } else {
        data.launchDate = undefined;
      }
      if (data.icrDocumentNumber) {
        data.icrDocumentNumber = data.icrDocumentNumber.id;
      }
      /* eslint-enable no-param-reassign */

      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        if (plc) {
          const plcUrlComponent = encodeURIComponent(plc.id);

          await axios({
            method: 'PATCH',
            url: `/api/plcs/${plcUrlComponent}`,
            data,
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/plcs`,
            data,
          });
        }

        // Close the modal
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }

        setSuccess('Successfully saved changes!');
      } catch (err) {
        setError(err?.response?.data?.error || err?.message);
      }
      setLoading(false);
    },
    [plc, setTableReload, setSuccess]
  );

  const handleOpen = React.useCallback(
    (event) => {
      event.stopPropagation();
      reset(defaultValues);
      setOpen(true);
    },
    [defaultValues, reset]
  );
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  //---------------------------------------------------------------------------
  // Delete Confirmation Form
  //---------------------------------------------------------------------------
  const { roles } = useAuth0Roles();
  const canDelete = React.useMemo(() => roles.includes('Scorecard Admin'), [roles]);
  const handleDeleteConfirmation = React.useCallback(async () => {
    if (plc) {
      const plcUrlComponent = encodeURIComponent(plc.id);
      await axios({
        method: 'DELETE',
        url: `/api/plcs/${plcUrlComponent}`,
      });

      // Reset and close the modal
      handleClose();

      // Update the reload state to trigger a data re-fetch
      if (setTableReload) {
        setTableReload(true);
      }
    }
  }, [handleClose, plc, setTableReload]);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {plc?.id ? (
        <IconButton
          color="primary"
          onClick={handleOpen}
          disabled={!documents.length}
          sx={{ display: defaultValues.disabled ? 'none' : 'block' }}
          data-cy={`edit-plc-row-${plc?.id}`}
        >
          <Edit />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={handleOpen}
          disabled={!documents.length}
          startIcon={<RocketLaunchIcon />}
          data-cy="add-plc"
        >
          Add PLC
        </Button>
      )}
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        onClick={disablePropagation}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <RocketLaunchIcon color="secondary" sx={{ mr: 2 }} />
          {formTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                label="Name"
                name="name"
                required
                rules={{
                  required: 'Please provide a product name for this PLC',
                  maxLength: {
                    value: 255,
                    message: 'Product name should be less than 256 characters',
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormAutocompleteInput
                control={control}
                label="ICR Document Number"
                name="icrDocumentNumber"
                required
                options={documentOptions}
                rules={{
                  required: 'An ICR is required before creating a PLC.',
                  validate: (value) => checkDocumentExists(Number(value)),
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <FormStringInput
                control={control}
                label="Projected Revenue"
                name="thirdYearRevenue"
                required
                rules={{
                  required:
                    'A revenue estimate is required; the ICR should contain this information.',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <FormDatePicker
                control={control}
                label="Engineering Release"
                name="engineeringRelease"
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <FormDatePicker
                control={control}
                label="(Soft) Launch Date"
                name="launchDate"
                required
                rules={{
                  required: 'Please provide an estimated launch date for this product',
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2, marginRight: 'auto' }}>
            {!!plc && canDelete && (
              <ConfirmAction
                actionName="Delete"
                objectName="PLC"
                handleConfirmation={handleDeleteConfirmation}
                confirmKeyword={plc.name}
              />
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              {plc?.id ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PLCForm;

import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import Edit from '@mui/icons-material/Edit';
import HomeRepairService from '@mui/icons-material/HomeRepairService';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import IconButton from '@mui/material/IconButton';

import axios from '../axiosClient.js';
import PageContext from '../contexts/PageContext.jsx';
import useAlertSnackbar from '../hooks/useAlertSnackbar.jsx';
import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';
import ConfirmAction from './ConfirmAction.jsx';

function DataPointForm({
  // Props
  id,
  date,
  value,
  notes,
  metric,
  setTableReload,
}) {
  // This component doesn't care about the current state of the success message
  const [, setSuccess] = useAlertSnackbar('success', 30_000);

  const formTitle = React.useMemo(
    () => (id ? `Update Data Point (${metric.goalUnits})` : `Add Data Point (${metric.goalUnits})`),
    [id, metric.goalUnits]
  );
  const disablePropagation = React.useCallback((event) => {
    event.stopPropagation();
  }, []);

  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Form Submission
  //---------------------------------------------------------------------------
  const defaultValues = React.useMemo(
    () => ({
      date: date || '',
      value: value || '',
      notes: notes || '',
    }),
    [date, notes, value]
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues,
    shouldUnregister: true,
  });

  const { setEditMode } = React.useContext(PageContext);
  const { isDirty } = useFormState({ control });

  const onSubmit = React.useCallback(
    async (data) => {
      // Disable the submit button and display the spinner
      setLoading(true);

      try {
        const metricUrlComponent = encodeURIComponent(metric.id);
        if (id) {
          const dataPointUrlComponent = encodeURIComponent(id);

          await axios({
            method: 'PATCH',
            url: `/api/data-points/${metricUrlComponent}/${dataPointUrlComponent}`,
            data: {
              date: data.date,
              value: Number(data.value),
              notes: data.notes,
            },
          });
        } else {
          await axios({
            method: 'POST',
            url: `/api/data-points/${metricUrlComponent}`,
            data: {
              date: data.date,
              value: Number(data.value),
              notes: data.notes,
            },
          });
        }

        // Close the modal
        setOpen(false);

        // Update the reload state to trigger a data re-fetch
        if (setTableReload) {
          setTableReload(true);
        }

        setSuccess('Successfully saved changes!');
      } catch (err) {
        setError(err?.response?.data?.error || err?.response?.data?.message || err.message);
      }
      setEditMode(false);
      setLoading(false);
    },
    [setEditMode, metric.id, id, setTableReload, setSuccess]
  );

  //---------------------------------------------------------------------------
  // Disable Confirmation Form
  //---------------------------------------------------------------------------
  const handleDeleteConfirmation = React.useCallback(async () => {
    if (id) {
      const metricUrlComponent = encodeURIComponent(metric.id);
      const dataPointUrlComponent = encodeURIComponent(id);
      await axios({
        method: 'DELETE',
        url: `/api/data-points/${metricUrlComponent}/${dataPointUrlComponent}`,
      });

      // Close the modal
      setOpen(false);

      // Update the reload state to trigger a data re-fetch
      if (setTableReload) {
        setTableReload(true);
      }
    }
  }, [id, metric.id, setTableReload]);

  const handleOpen = React.useCallback(
    (event) => {
      event.stopPropagation();
      reset(defaultValues);
      setOpen(true);
    },
    [defaultValues, reset]
  );
  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  //---------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------
  return (
    <>
      {id ? (
        <IconButton color="primary" onClick={handleOpen} data-cy={`edit-data-point-row-${id}`}>
          <Edit />
        </IconButton>
      ) : (
        <IconButton color="primary" onClick={handleOpen} data-cy={`edit-data-point-row-${id}`}>
          <AddCircleIcon />
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        id="DataPointForm"
        onClick={disablePropagation}
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(onSubmit),
          noValidate: true,
        }}
      >
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <HomeRepairService color="secondary" sx={{ mr: 2 }} />
          {formTitle}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4} sx={{ alignItems: 'center' }}>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                disabled
                control={control}
                label="Date"
                name="date"
                required
                rules={{
                  required: 'Please provide a date for this data point',
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <FormStringInput
                control={control}
                label="Value"
                name="value"
                type="number"
                autoFocus
                required
                rules={{
                  required: 'Please enter a value.',
                  min: 0,
                }}
              />
            </Grid>
            <Grid size={12}>
              <FormStringInput
                control={control}
                label="Notes"
                name="notes"
                rows={4}
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'Notes should be less than 256 characters',
                  },
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 2, marginRight: 'auto' }}>
            {!!id && (
              <ConfirmAction
                actionName="Delete"
                objectName="data point"
                handleConfirmation={handleDeleteConfirmation}
                confirmKeyword={date}
              />
            )}
          </Box>
          <Box sx={{ m: 2 }}>
            <CancelButton color="secondary" isDirty={isDirty} onClick={handleClose}>
              Cancel
            </CancelButton>
          </Box>
          <Box sx={{ m: 2 }}>
            <LoadingButton
              id="submitDeviceVariantButton"
              disabled={loading}
              variant="contained"
              color="secondary"
              loading={loading}
              type="submit"
            >
              {id ? 'Update' : 'Add'}
            </LoadingButton>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DataPointForm;

import React from 'react';
import { useForm, useFormState } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';

import Alert from './common/Alert.jsx';
import CancelButton from './common/CancelButton.jsx';
import FormStringInput from './common/FormStringInput.jsx';

function ConfirmAction({
  // Props
  actionName,
  objectName,
  handleConfirmation,
  confirmKeyword,
  dialogTitle,
  buttonVariant = 'text',
  buttonIcon,
  disabled,
}) {
  //---------------------------------------------------------------------------
  // Modal state management
  //---------------------------------------------------------------------------
  const [open, setOpen] = React.useState(false);

  //---------------------------------------------------------------------------
  // Update button state management
  //---------------------------------------------------------------------------
  const [loading, setLoading] = React.useState(false);

  //---------------------------------------------------------------------------
  // Error alerting state management
  //---------------------------------------------------------------------------
  const [error, setError] = React.useState(null);

  const confirmButtonText = `${actionName} ${objectName}`;
  const confirmDialogTitleText = `${actionName} ${objectName}${dialogTitle ? ` - ${dialogTitle}` : ''}`;

  const { handleSubmit, control, reset } = useForm({
    defaultValues: { keyword: '' },
    shouldUnregister: true,
  });
  const { isDirty } = useFormState({ control });

  const closeForm = React.useCallback(() => setOpen(false), []);
  const handleClick = React.useCallback(() => {
    reset();
    setOpen(true);
  }, [reset]);
  const onSubmit = React.useCallback(async () => {
    // Disable the submit button and display the spinner
    setLoading(true);
    try {
      await handleConfirmation();
      setOpen(false);
    } catch (err) {
      setError(err?.response?.data?.error || err?.message);
    }
    setLoading(false);
  }, [handleConfirmation]);

  return (
    <>
      <Button
        variant={buttonVariant}
        color="secondary"
        disabled={disabled}
        startIcon={buttonIcon}
        sx={
          buttonVariant !== 'text'
            ? {}
            : {
                marginLeft: 'auto',
                marginRight: '2.5rem',
                color: 'text.secondary',
                '&:hover': { textDecoration: 'underline' },
              }
        }
        onClick={handleClick}
      >
        {confirmButtonText}
      </Button>
      <Dialog open={open} onClose={closeForm} maxWidth="sm" fullWidth>
        <Alert message={error} setMessage={setError} level="error" data-cy="error-alert" />
        <DialogTitle>{confirmDialogTitleText}</DialogTitle>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogContent>
            <DialogContentText data-cy="confirmation-text">
              If you are sure you want to {actionName.toLowerCase()} this {objectName}, type{' '}
              <strong>{confirmKeyword}</strong> into the field below and press the confirmation
              button.
            </DialogContentText>
            <br />
            <FormStringInput
              control={control}
              name="keyword"
              variant="outlined"
              rules={{
                required: `Please enter "${confirmKeyword}" to proceed.`,
                validate: (val) => {
                  if (val !== confirmKeyword) {
                    return `Please enter "${confirmKeyword}" to proceed.`;
                  }
                  return true;
                },
              }}
            />
          </DialogContent>
          <DialogActions>
            <Box sx={{ m: 2 }}>
              <CancelButton color="secondary" isDirty={isDirty} onClick={closeForm}>
                Cancel
              </CancelButton>
            </Box>
            <Box sx={{ m: 2 }}>
              <LoadingButton
                disabled={loading}
                data-cy={`${objectName}-confirm`}
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
              >
                Confirm
              </LoadingButton>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default ConfirmAction;
